import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "validation-error" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { class: "buttons" }

import { Layout } from '@/components/Layouts';
import { Block, CustomButton, PageHeading, PageSection } from '@/components/Common';
import urls from '@/urls';
import api from '@/api';
import { ApiError } from '@/models/ApiError';
import { AxiosError } from 'axios';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useParams, useBunch } from '@/composables';
import { useMutation } from '@tanstack/vue-query';
import { MessageResponse } from '@/response/MessageResponse';


export default /*@__PURE__*/_defineComponent({
  __name: 'JoinBunchPage',
  setup(__props) {

const { slug, code } = useParams();
const router = useRouter();
const { bunch, bunchReady } = useBunch(slug.value);

const inputCode = ref('');
const errorMessage = ref<string>();

const bunchName = computed(() => bunch.value.name);
const ready = computed(() => bunchReady.value);

const join = () => {
  joinBunchMutation.mutate();
};

const joinBunchMutation = useMutation({
  mutationFn: async (): Promise<MessageResponse> => {
    const data = {
      code: code.value ?? inputCode.value,
    };

    const response = await api.joinBunch(slug.value, data);
    return response.data;
  },
  onSuccess: () => {
    router.push(urls.bunch.details(slug.value));
  },
  onError: (error: AxiosError<ApiError>) => {
    const message = error.response?.data.message || 'Unknown Error';
    errorMessage.value = message;
  },
});

const cancel = () => {
  router.push(urls.home);
};

watch(ready, () => {
  if (ready.value && code.value) join();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "Join Bunch" })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createElementVNode("p", null, "Please enter your invitation code below to join the bunch " + _toDisplayString(bunchName.value), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          (errorMessage.value)
            ? (_openBlock(), _createBlock(_unref(Block), { key: 0 }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_1, _toDisplayString(errorMessage.value), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _cache[1] || (_cache[1] = _createElementVNode("label", {
                  class: "label",
                  for: "invitationCode"
                }, "Invitation Code", -1 /* HOISTED */)),
                _withDirectives(_createElementVNode("input", {
                  class: "longfield",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputCode).value = $event)),
                  id: "invitationCode",
                  type: "text"
                }, null, 512 /* NEED_PATCH */), [
                  [_vModelText, inputCode.value]
                ])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(CustomButton), {
                  onClick: join,
                  type: "action",
                  text: "Join"
                }),
                _createVNode(_unref(CustomButton), {
                  onClick: cancel,
                  text: "Cancel"
                })
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})