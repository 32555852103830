import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "form" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = {
  key: 0,
  class: "field"
}
const _hoisted_4 = ["bind"]
const _hoisted_5 = { class: "buttons" }

import validate from '@/validate';
import forms from '@/forms';
import { CustomButton } from '@/components/Common';
import { computed, onMounted, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BuyinForm',
  props: {
    suggestedBuyin: {},
    isPlayerInGame: { type: Boolean }
  },
  emits: ['buyin', 'cancel'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const strAmount = ref('0');
const strStack = ref('0');
const buyinError = ref<string | null>(null);
const stackError = ref<string | null>(null);

const hasErrors = computed(() => {
  return buyinError.value === null && stackError.value === null;
});

const amount = computed(() => forms.parseInt(strAmount.value));
const stack = computed(() => forms.parseInt(strStack.value));

const buyin = () => {
  validateForm();
  if (!hasErrors.value) {
    emit('buyin', amount.value, stack.value);
  }
};

const cancel = () => {
  emit('cancel');
};

const focus = (e: FocusEvent) => {
  if (e.target) forms.selectAll(e.target as HTMLInputElement);
};

const validateForm = () => {
  clearErrors();
  if (validate.intRange(amount.value, 1)) buyinError.value = 'Buyin must be greater than zero';
  if (validate.intRange(stack.value, 0)) stackError.value = "Stack can't be negative";
};

const clearErrors = () => {
  buyinError.value = null;
  stackError.value = null;
};

onMounted(() => {
  strAmount.value = props.suggestedBuyin.toString();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("label", {
        class: "label",
        for: "buyin-amount"
      }, "Amount", -1 /* HOISTED */)),
      _withDirectives(_createElementVNode("input", {
        class: "numberfield",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((strAmount).value = $event)),
        onFocus: focus,
        id: "buyin-amount",
        type: "text",
        inputmode: "numeric",
        pattern: "[0-9]*"
      }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
        [_vModelText, strAmount.value]
      ])
    ]),
    (_ctx.isPlayerInGame)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("label", {
            class: "label",
            for: "buyin-stack"
          }, "Stack Size", -1 /* HOISTED */)),
          _createElementVNode("input", {
            class: "numberfield",
            bind: _ctx.suggestedBuyin,
            onFocus: focus,
            id: "buyin-stack",
            type: "text",
            inputmode: "numeric",
            pattern: "[0-9]*"
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_unref(CustomButton), {
        onClick: buyin,
        type: "action",
        text: "Buy In"
      }),
      _createVNode(_unref(CustomButton), {
        onClick: cancel,
        text: "Cancel"
      })
    ])
  ]))
}
}

})