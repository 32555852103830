import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

import { CustomLink } from '@/components/Common';
import { ButtonType } from '@/models/ButtonType';
import { CssClasses } from '@/models/CssClasses';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomButton',
  props: {
    type: { default: 'default' },
    text: {},
    url: {}
  },
  emits: ['click'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const hasUrl = computed((): boolean => !!props.url);

const cssClasses = computed((): CssClasses => {
  return {
    button: true,
    'button--action': props.type === 'action',
  };
});

const click = () => {
  emit('click');
};

return (_ctx: any,_cache: any) => {
  return (hasUrl.value)
    ? (_openBlock(), _createBlock(_unref(CustomLink), {
        key: 0,
        url: _ctx.url,
        cssClasses: cssClasses.value
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.text), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["url", "cssClasses"]))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        onClick: click,
        class: _normalizeClass(cssClasses.value)
      }, _toDisplayString(_ctx.text), 3 /* TEXT, CLASS */))
}
}

})