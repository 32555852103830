import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

import urls from '@/urls';
import api from '@/api';
import { Layout } from '@/components/Layouts';
import { Block, CustomButton, CustomLink, ErrorMessage, PageHeading, PageSection } from '@/components/Common';
import { AxiosError } from 'axios';
import { ApiError } from '@/models/ApiError';
import { ApiParamsAddBunch } from '@/models/ApiParamsAddBunch';
import TimezoneDropdown from '@/components/TimezoneDropdown.vue';
import CurrencyLayoutDropdown from '@/components/CurrencyLayoutDropdown.vue';
import { useTimezones } from '@/composables';
import { computed, ref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { bunchListKey, userBunchListKey } from '@/queries/queryKeys';
import { BunchResponse } from '@/response/BunchResponse';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddBunchPage',
  setup(__props) {

const timezones = useTimezones();
const defaultTimezone = timezones.getDefaultTimezone();
const queryClient = useQueryClient();

const description = ref('');
const name = ref('');
const currencySymbol = ref('$');
const currencyLayout = ref('');
const timezone = ref(defaultTimezone);
const errorMessage = ref('');
const bunchAdded = ref(false);
const savedSlug = ref('');

const hasError = computed(() => !!errorMessage.value);
const bunchUrl = computed(() => urls.bunch.details(savedSlug.value));

const save = async () => {
  addBunchMutation.mutate();
};

const addBunchMutation = useMutation({
  mutationFn: async () => {
    errorMessage.value = '';

    const params: ApiParamsAddBunch = {
      name: name.value,
      description: description.value,
      currencySymbol: currencySymbol.value,
      currencyLayout: currencyLayout.value,
      timezone: timezone.value,
    };

    const response = await api.addBunch(params);
    return response.data;
  },
  onSuccess: (response: BunchResponse) => {
    queryClient.invalidateQueries({ queryKey: bunchListKey() });
    queryClient.invalidateQueries({ queryKey: userBunchListKey(true) });
    savedSlug.value = response.id;
    bunchAdded.value = true;
  },
  onError: (err) => {
    const error = err as AxiosError<ApiError>;
    errorMessage.value = error.response?.data.message || 'Unknown Error';
  },
});

const back = () => {
  history.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: true
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "Create Bunch" })
            ]),
            _: 1 /* STABLE */
          }),
          (bunchAdded.value)
            ? (_openBlock(), _createBlock(_unref(Block), { key: 0 }, {
                default: _withCtx(() => [
                  _cache[6] || (_cache[6] = _createElementVNode("p", null, "Your bunch has been created.", -1 /* HOISTED */)),
                  _createElementVNode("p", null, [
                    _createVNode(_unref(CustomLink), { url: bunchUrl.value }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("Go to bunch!")
                      ])),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["url"])
                  ])
                ]),
                _: 1 /* STABLE */
              }))
            : (_openBlock(), _createBlock(_unref(Block), { key: 1 }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, [
                    _cache[7] || (_cache[7] = _createElementVNode("label", {
                      class: "label",
                      for: "name"
                    }, "Name", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      class: "textfield",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((name).value = $event)),
                      id: "name",
                      type: "text"
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, name.value]
                    ])
                  ]),
                  _createElementVNode("p", null, [
                    _cache[8] || (_cache[8] = _createElementVNode("label", {
                      class: "label",
                      for: "description"
                    }, "Description", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      class: "textfield",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((description).value = $event)),
                      id: "description",
                      type: "text"
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, description.value]
                    ])
                  ]),
                  _createElementVNode("p", null, [
                    _cache[9] || (_cache[9] = _createElementVNode("label", {
                      class: "label",
                      for: "currencySymbol"
                    }, "Currency Symbol", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      class: "textfield",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((currencySymbol).value = $event)),
                      id: "currencySymbol",
                      type: "text"
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, currencySymbol.value]
                    ])
                  ]),
                  _createElementVNode("p", null, [
                    _cache[10] || (_cache[10] = _createElementVNode("label", {
                      class: "label",
                      for: "currencyLayout"
                    }, "Currency Layout", -1 /* HOISTED */)),
                    _createVNode(CurrencyLayoutDropdown, {
                      modelValue: currencyLayout.value,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((currencyLayout).value = $event)),
                      symbol: currencySymbol.value
                    }, null, 8 /* PROPS */, ["modelValue", "symbol"])
                  ]),
                  _createElementVNode("p", null, [
                    _cache[11] || (_cache[11] = _createElementVNode("label", {
                      class: "label",
                      for: "timezone"
                    }, "Timezone", -1 /* HOISTED */)),
                    _createVNode(TimezoneDropdown, {
                      modelValue: timezone.value,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((timezone).value = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _createVNode(_unref(ErrorMessage), { message: errorMessage.value }, null, 8 /* PROPS */, ["message"]),
                  _createElementVNode("p", null, [
                    _createVNode(_unref(CustomButton), {
                      onClick: save,
                      type: "action",
                      text: "Save"
                    }),
                    _createVNode(_unref(CustomButton), {
                      onClick: back,
                      text: "Cancel"
                    })
                  ])
                ]),
                _: 1 /* STABLE */
              }))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})