import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "buttons" }
const _hoisted_3 = { key: 0 }

import urls from '@/urls';
import { Layout } from '@/components/Layouts';
import BunchNavigation from '@/components/Navigation/BunchNavigation.vue';
import { Block, CustomButton, DurationText, ErrorMessage, PageHeading, PageSection, WinningsText } from '@/components/Common';
import { ValueList, ValueListKey, ValueListValue } from '@/components/Common/ValueList';
import { ArchiveCashgame } from '@/models/ArchiveCashgame';
import api from '@/api';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { usePlayerList, useGameList, useParams, useBunch, useUser } from '@/composables';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { playerListKey } from '@/queries/queryKeys';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayerDetailsPage',
  setup(__props) {

const { slug, playerId } = useParams();
const router = useRouter();
const { localization, bunchReady } = useBunch(slug.value);
const { getPlayer, tryGetPlayer, playersReady } = usePlayerList(slug.value);
const { allGames, gamesReady } = useGameList(slug.value);
const queryClient = useQueryClient();

const isInvitationFormVisible = ref(false);
const inviteEmail = ref('');
const invitationSent = ref(false);
const errorMessage = ref('');

const hasUser = computed(() => Boolean(tryGetPlayer(playerId.value)?.userId));
const player = computed(() => getPlayer(playerId.value));

const { user, userReady } = useUser(player.value.userName ?? '', hasUser.value);

const playerName = computed(() => player.value.name);
const inviteUrl = computed(() => urls.player.invite(player.value.id));

const userUrl = computed(() => {
  if (user.value) return urls.user.details(user.value.userName);
});

const avatarUrl = computed(() => user.value?.avatar);
const games = computed(() => allGames.value.filter((g) => isInGame(g)));

const results = computed(() => {
  let results = [];
  for (const game of games.value) {
    for (const p of game.players) {
      if (p.id === player.value.id) {
        results.push(p);
        break;
      }
    }
  }
  return results;
});

const totalResult = computed(() => results.value.reduce((acc, cur) => acc + cur.winnings, 0));

const bestResult = computed(() => {
  let best: number | null = null;
  for (const result of results.value) {
    if (best == null || result.winnings > best) best = result.winnings;
  }
  return best ?? 0;
});

const worstResult = computed(() => {
  let worst: number | null = null;
  for (const result of results.value) {
    if (worst == null || result.winnings < worst) worst = result.winnings;
  }
  return worst ?? 0;
});

const gamesPlayed = computed(() => games.value.length);

const timePlayed = computed(() => {
  return results.value.reduce((acc, cur) => acc + cur.timePlayed, 0);
});

const totalWins = computed(() => {
  let count = 0;
  for (const game of games.value) {
    if (game.isBestPlayer(player.value.id)) count += 1;
  }
  return count;
});

const currentStreak = computed(() => {
  let lastStreak = 0;
  let currentStreak = 0;
  for (var result of results.value) {
    if (result.winnings >= 0) {
      currentStreak++;
    } else {
      currentStreak--;
    }
    if (Math.abs(currentStreak) < Math.abs(lastStreak)) {
      return lastStreak;
    }
    lastStreak = currentStreak;
  }
  return lastStreak;
});

const bestWinningStreak = computed(() => {
  let bestStreak = 0;
  let currentStreak = 0;
  for (const result of results.value) {
    if (result.winnings >= 0) {
      currentStreak++;
      if (currentStreak > bestStreak) {
        bestStreak = currentStreak;
      }
    } else {
      currentStreak = 0;
    }
  }
  return bestStreak;
});

const formattedCurrentStreak = computed(() => {
  if (currentStreak.value === 0) return '-';

  const wonOrLost = currentStreak.value > 0 ? 'Won' : 'Lost';
  const streak = Math.abs(currentStreak.value);
  return formatStreak(wonOrLost, streak);
});

const formattedWinningStreak = computed(() => formatStreak('Won', bestWinningStreak.value));
const formattedLosingStreak = computed(() => formatStreak('Lost', worstLosingStreak.value));

const worstLosingStreak = computed(() => {
  let worstStreak = 0;
  let currentStreak = 0;
  for (var result of results.value) {
    if (result.winnings < 0) {
      currentStreak++;
      if (currentStreak > worstStreak) {
        worstStreak = currentStreak;
      }
    } else {
      currentStreak = 0;
    }
  }
  return worstStreak;
});

const ready = computed(() => bunchReady.value && playersReady.value && gamesReady.value && (userReady.value || !hasUser.value));
const canDelete = computed(() => results.value.length === 0);

const showInvitationForm = () => {
  isInvitationFormVisible.value = true;
};

const hideInvitationForm = () => {
  isInvitationFormVisible.value = false;
};

const invitePlayer = () => {
  api.invitePlayer(player.value.id, { email: inviteEmail.value });
  invitationSent.value = true;
  hideInvitationForm();
};

const cancelInvitation = () => {
  hideInvitationForm();
};

const notRegisteredMessage = computed(() => {
  return invitationSent.value ? 'An invitation was sent.' : 'This player is not registered yet.';
});

const deleteMutation = useMutation({
  mutationFn: async () => {
    await api.deletePlayer(playerId.value);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: playerListKey(slug.value) });
    router.push(urls.player.list(slug.value));
  },
  onError: () => {
    errorMessage.value = 'Server error';
  },
});

const deletePlayer = () => {
  if (window.confirm('Do you want to delete this player?')) {
    deleteMutation.mutate();
  }
};

const formatStreak = (wonOrLost: string, gameCount: number) => {
  const gamesText = formatStreakGames(gameCount);
  return `${wonOrLost} in ${gameCount} ${gamesText}`;
};

const formatStreakGames = (streak: number) => (streak === 1 ? 'game' : 'games');

const isInGame = (game: ArchiveCashgame) => {
  if (!playersReady) return false;
  for (const p of game.players) {
    if (p.id === player.value.id) return true;
  }
  return false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    "top-nav": _withCtx(() => [
      _createVNode(BunchNavigation)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: playerName.value }, null, 8 /* PROPS */, ["text"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("h2", null, "Player Facts", -1 /* HOISTED */)
            ])),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(ValueList), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(ValueListKey), null, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Total Result")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListValue), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(WinningsText), {
                        value: totalResult.value,
                        "show-currency": true,
                        localization: _unref(localization)
                      }, null, 8 /* PROPS */, ["value", "localization"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListKey), null, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("Best Result")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListValue), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(WinningsText), {
                        value: bestResult.value,
                        "show-currency": true,
                        localization: _unref(localization)
                      }, null, 8 /* PROPS */, ["value", "localization"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListKey), null, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("Worst Result")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListValue), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(WinningsText), {
                        value: worstResult.value,
                        "show-currency": true,
                        localization: _unref(localization)
                      }, null, 8 /* PROPS */, ["value", "localization"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListKey), null, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Games Played")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListValue), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(gamesPlayed.value), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListKey), null, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Time Played")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListValue), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DurationText), { value: timePlayed.value }, null, 8 /* PROPS */, ["value"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListKey), null, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("Total Wins")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListValue), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(totalWins.value), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListKey), null, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("Current Streak")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListValue), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(formattedCurrentStreak.value), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListKey), null, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("Best Winning Streak")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListValue), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(formattedWinningStreak.value), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListKey), null, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("Worst Losing Streak")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListValue), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(formattedLosingStreak.value), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        aside2: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createElementVNode("h2", null, "User", -1 /* HOISTED */)
            ])),
            _: 1 /* STABLE */
          }),
          (hasUser.value)
            ? (_openBlock(), _createBlock(_unref(Block), { key: 0 }, {
                default: _withCtx(() => [
                  _createCommentVNode(" <p>\r\n              <img :src=\"avatarUrl\" alt=\"User avatar\" />\r\n            </p> "),
                  _cache[12] || (_cache[12] = _createElementVNode("p", null, "This player is a registered user.", -1 /* HOISTED */)),
                  _createElementVNode("p", null, [
                    _createVNode(_unref(CustomButton), {
                      url: userUrl.value,
                      text: "View User Profile"
                    }, null, 8 /* PROPS */, ["url"])
                  ])
                ]),
                _: 1 /* STABLE */
              }))
            : (_openBlock(), _createBlock(_unref(Block), { key: 1 }, {
                default: _withCtx(() => [
                  (isInvitationFormVisible.value)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("div", _hoisted_1, [
                          _cache[13] || (_cache[13] = _createElementVNode("label", {
                            class: "label",
                            for: "inviteEmail"
                          }, "Email", -1 /* HOISTED */)),
                          _withDirectives(_createElementVNode("input", {
                            class: "textfield",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inviteEmail).value = $event)),
                            id: "inviteEmail",
                            type: "email"
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, inviteEmail.value]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_unref(CustomButton), {
                            onClick: invitePlayer,
                            text: "Invite",
                            type: "action"
                          }),
                          _createVNode(_unref(CustomButton), {
                            onClick: cancelInvitation,
                            text: "Cancel"
                          })
                        ])
                      ], 64 /* STABLE_FRAGMENT */))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("p", null, _toDisplayString(notRegisteredMessage.value), 1 /* TEXT */),
                        (!invitationSent.value)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                              _createVNode(_unref(CustomButton), {
                                onClick: showInvitationForm,
                                text: "Invite Player",
                                type: "action"
                              })
                            ]))
                          : _createCommentVNode("v-if", true)
                      ], 64 /* STABLE_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }))
        ]),
        _: 1 /* STABLE */
      }),
      (canDelete.value)
        ? (_openBlock(), _createBlock(_unref(PageSection), { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_unref(Block), null, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createElementVNode("h2", null, "Delete Player", -1 /* HOISTED */)
                ])),
                _: 1 /* STABLE */
              }),
              _createVNode(_unref(Block), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(ErrorMessage), { message: errorMessage.value }, null, 8 /* PROPS */, ["message"]),
                  _createElementVNode("p", null, [
                    _createVNode(_unref(CustomButton), {
                      onClick: deletePlayer,
                      text: "Delete Player",
                      type: "action"
                    })
                  ])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})