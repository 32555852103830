import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "standings" }

import urls from '@/urls';
import timeFunctions from '@/time-functions';
import { Layout } from '@/components/Layouts';
import BunchNavigation from '@/components/Navigation/BunchNavigation.vue';
import GameButton from '@/components/CurrentGame/GameButton.vue';
import ReportForm from '@/components/CurrentGame/ReportForm.vue';
import BuyinForm from '@/components/CurrentGame/BuyinForm.vue';
import CashoutForm from '@/components/CurrentGame/CashoutForm.vue';
import PlayerDropdown from '@/components/PlayerDropdown.vue';
import PlayerTable from '@/components/CurrentGame/PlayerTable.vue';
import GameChart from '@/components/CurrentGame/GameChart.vue';
import { Block, CustomButton, CustomLink, DurationText, PageHeading, PageSection } from '@/components/Common';
import { ValueList, ValueListKey, ValueListValue } from '@/components/Common/ValueList';
import LocationDropdown from '@/components/LocationDropdown.vue';
import EventDropdown from '@/components/EventDropdown.vue';
import format from '@/format';
import dayjs from 'dayjs';
import api from '@/api';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { DetailedCashgamePlayer } from '@/models/DetailedCashgamePlayer';
import { BuyinIcon, CashoutIcon, ReportIcon } from '../Icons';
import { useParams, useLocationList, useBunch, usePlayerList, useEventList, useGame } from '@/composables';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { gameKey, gameListKey } from '@/queries/queryKeys';
import { SaveActionEmitData } from '@/models/SaveActionEmitData';
import { DetailedCashgameResponseActionType } from '@/response/DetailedCashgameResponseActionType';


export default /*@__PURE__*/_defineComponent({
  __name: 'CashgameDetailsPage',
  setup(__props) {

const { slug, cashgameId } = useParams();
const router = useRouter();
const { bunch, localization, isManager, bunchReady } = useBunch(slug.value);
const { players, playersReady } = usePlayerList(slug.value);
const { locations, locationsReady } = useLocationList(slug.value);
const { events, eventsReady } = useEventList(slug.value);

const reportFormVisible = ref(false);
const buyinFormVisible = ref(false);
const cashoutFormVisible = ref(false);
const selectedPlayerId = ref('');
const isEditing = ref(false);
const locationId = ref<string>();
const eventId = ref<string>();

const { game: cashgame, gameReady } = useGame(cashgameId.value);
const queryClient = useQueryClient();

const title = computed(() => `Cashgame ${formattedDate.value}`);
const formattedDate = computed(() => format.monthDayYear(startTime.value));
const formattedStartTime = computed(() => format.hourMinute(startTime.value));
const formattedEndTime = computed(() => {
  if (!cashgame.value) return '';
  return format.hourMinute(cashgame.value.updatedTime);
});

const durationMinutes = computed(() => {
  if (!cashgame.value) return 0;
  return timeFunctions.diffInMinutes(startTime.value, cashgame.value.updatedTime);
});

const showStartTime = computed(() => hasPlayers.value);
const showEndTime = computed(() => isEnded.value);
const showDuration = computed(() => isEnded.value);
const isRunning = computed(() => !!(cashgame.value?.isRunning ?? false));
const isInGame = computed(() => !!(playerInGame.value ?? false));
const canReport = computed(() => isInGame.value && !hasCachedOut.value);
const canBuyin = computed(() => !hasCachedOut.value);
const canCashout = computed(() => isInGame.value);

const hasCachedOut = computed(() => {
  if (!playerInGame.value) return false;
  return playerInGame.value.hasCashedOut();
});

const isEnded = computed(() => hasPlayers.value && !isRunning.value);
const areButtonsVisible = computed(() => isRunning.value && !isAnyFormVisible.value);
const isAnyFormVisible = computed(
  () => (isRunning.value && reportFormVisible.value) || buyinFormVisible.value || cashoutFormVisible.value
);
const isPlayerSelectionEnabled = computed(() => isRunning.value && isManager.value && !isEditing.value);
const locationName = computed(() => cashgame.value?.location.name || '');

const locationUrl = computed(() => {
  if (!cashgame.value) return '';
  return urls.location.details(slug.value, cashgame.value.location.id);
});

const isPartOfEvent = computed(() => Boolean(cashgame.value?.event));
const eventName = computed(() => cashgame.value?.event?.name || '');

const eventUrl = computed(() => {
  if (!cashgame.value) return '';

  if (!cashgame.value.event) return '';

  return urls.event.details(slug.value, cashgame.value.event.id);
});

const canEdit = computed((): boolean => isManager.value);

const playersInGame = computed((): DetailedCashgamePlayer[] => {
  if (!cashgame.value) return [];
  return cashgame.value.players.slice().sort((left, right) => right.getWinnings() - left.getWinnings());
});

const allPlayers = computed(() => players.value);
const hasPlayers = computed(() => Boolean(playersInGame.value.length));

const suggestedBuyin = computed(() => {
  var p = playerInGame.value;
  if (p === null) return bunch.value.defaultBuyin;

  var buyins = p.buyins();
  if (buyins.length === 0) return bunch.value.defaultBuyin;

  return buyins[buyins.length - 1].added ?? 0;
});

const suggestedReport = computed(() => {
  var p = playerInGame.value;
  if (p === null) return 0;

  var reports = p.reports();
  if (reports.length === 0) return suggestedBuyin.value;

  return reports[reports.length - 1].stack;
});

const suggestedCashout = computed(() => {
  var p = playerInGame.value;
  if (p === null) return 0;

  var cashouts = p.cashouts();
  if (cashouts.length === 0) return suggestedReport.value;

  return cashouts[cashouts.length - 1].stack;
});

const playerInGame = computed(() => getPlayerInGame(selectedPlayerId.value));

const startTime = computed(() => {
  let first;
  let t = dayjs().utc();
  const p = playersInGame.value;

  if (p.length === 0) return t.toDate();
  for (let i = 0; i < p.length; i++) {
    first = p[i].actions[0];
    if (first) {
      const firstTime = dayjs(first.time);
      if (firstTime.isBefore(t)) {
        t = firstTime;
      }
    }
  }
  return t.toDate();
});

const updatedTime = computed(() => cashgame.value?.updatedTime || null);
const ready = computed(
  () => bunchReady.value && gameReady.value && playersReady.value && locationsReady.value && eventsReady.value
);

const report = async (stack: number) => {
  reportMutation.mutate({ stack });
};

const reportMutation = useMutation({
  mutationFn: async (params: { stack: number }) => {
    const reportData = { type: 'report', playerId: selectedPlayerId.value, stack: params.stack };
    await api.report(cashgame.value.id, reportData);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: gameKey(cashgameId.value) });
    resetSelectedPlayerId();
    hideForms();
  },
});

const buyin = async (amount: number, stack: number) => {
  buyinMutation.mutate({ stack: stack, added: amount });
};

const buyinMutation = useMutation({
  mutationFn: async (params: { stack: number; added: number }) => {
    const buyinData = { type: 'buyin', playerId: selectedPlayerId.value, stack: params.stack, added: params.added };
    await api.buyin(cashgame.value.id, buyinData);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: gameKey(cashgameId.value) });
    resetSelectedPlayerId();
    hideForms();
  },
});

const cashout = async (stack: number) => {
  cashoutMutation.mutate({ stack });
};

const cashoutMutation = useMutation({
  mutationFn: async (params: { stack: number }) => {
    const cashoutData = { type: 'cashout', playerId: selectedPlayerId.value, stack: params.stack };
    await api.cashout(cashgame.value.id, cashoutData);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: gameKey(cashgameId.value) });
    resetSelectedPlayerId();
    hideForms();
  },
});

const showReportForm = () => {
  reportFormVisible.value = true;
};

const showBuyinForm = () => {
  buyinFormVisible.value = true;
};

const showCashoutForm = () => {
  cashoutFormVisible.value = true;
};

const hideForms = () => {
  reportFormVisible.value = false;
  buyinFormVisible.value = false;
  cashoutFormVisible.value = false;
};

const resetSelectedPlayerId = () => {
  selectedPlayerId.value = bunch.value.player.id;
};

const getPlayerInGame = (id: string) => {
  if (!id) return null;
  return playersInGame.value.find((p) => p.id.toString() === id.toString()) || null;
};

const onSelectPlayer = (id: string) => {
  if (isPlayerSelectionEnabled.value) selectedPlayerId.value = id;
};

const onEdit = () => {
  isEditing.value = true;
};

const onSave = async () => {
  if (!cashgame.value || !locationId.value) return;
  saveMutation.mutate();
};

const saveMutation = useMutation({
  mutationFn: async () => {
    await api.updateCashgame(cashgameId.value, {
      locationId: locationId.value,
      eventId: eventId.value !== '' ? eventId.value : null,
    });
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: gameKey(cashgameId.value) });
    isEditing.value = false;
  },
});

const onDelete = async () => {
  if (!cashgame.value || hasPlayers.value) return;
  deleteMutation.mutate();
};

const deleteMutation = useMutation({
  mutationFn: async () => {
    await api.deleteCashgame(cashgameId.value);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: gameListKey(cashgameId.value) });
    redirect();
  },
});

const onCancelEdit = () => {
  isEditing.value = false;
};

const onDeleteAction = async (id: string) => {
  deleteActionMutation.mutate({ id });
};

const deleteActionMutation = useMutation({
  mutationFn: async (params: { id: string }) => {
    await api.deleteAction(cashgame.value.id, params.id);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: gameKey(cashgameId.value) });
  },
});

const onSaveAction = async (data: SaveActionEmitData) => {
  updateActionMutation.mutate(data);
};

const updateActionMutation = useMutation({
  mutationFn: async (data: SaveActionEmitData) => {
    const updateData = {
      added: data.added,
      stack: data.stack,
      timestamp: data.time,
    };
    await api.updateAction(cashgame.value.id, data.id, updateData);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: gameKey(cashgameId.value) });
  },
});

const redirect = () => {
  router.push(urls.cashgame.index(slug.value));
};

const playerId = computed(() => bunch.value.player.id);

watch(cashgame, () => {
  locationId.value = cashgame.value?.location.id || undefined;
  eventId.value = cashgame.value?.event?.id || undefined;
  selectedPlayerId.value = playerId.value;
});

watch(bunch, () => {
  selectedPlayerId.value = bunch.value.player.id;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    "top-nav": _withCtx(() => [
      _createVNode(BunchNavigation)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: title.value }, null, 8 /* PROPS */, ["text"])
            ]),
            _: 1 /* STABLE */
          }),
          (areButtonsVisible.value)
            ? (_openBlock(), _createBlock(_unref(Block), { key: 0 }, {
                default: _withCtx(() => [
                  (canReport.value)
                    ? (_openBlock(), _createBlock(GameButton, {
                        key: 0,
                        text: "Report",
                        icon: "reorder",
                        onClick: showReportForm
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(ReportIcon))
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  (canBuyin.value)
                    ? (_openBlock(), _createBlock(GameButton, {
                        key: 1,
                        text: "Buy In",
                        icon: "money",
                        onClick: showBuyinForm
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(BuyinIcon))
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  (canCashout.value)
                    ? (_openBlock(), _createBlock(GameButton, {
                        key: 2,
                        text: "Cash Out",
                        icon: "signout",
                        onClick: showCashoutForm
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(CashoutIcon))
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              (reportFormVisible.value)
                ? (_openBlock(), _createBlock(ReportForm, {
                    key: 0,
                    suggestedReport: suggestedReport.value,
                    onReport: report,
                    onCancel: hideForms
                  }, null, 8 /* PROPS */, ["suggestedReport"]))
                : _createCommentVNode("v-if", true),
              (buyinFormVisible.value)
                ? (_openBlock(), _createBlock(BuyinForm, {
                    key: 1,
                    suggestedBuyin: suggestedBuyin.value,
                    onBuyin: buyin,
                    onCancel: hideForms,
                    isPlayerInGame: isInGame.value
                  }, null, 8 /* PROPS */, ["suggestedBuyin", "isPlayerInGame"]))
                : _createCommentVNode("v-if", true),
              (cashoutFormVisible.value)
                ? (_openBlock(), _createBlock(CashoutForm, {
                    key: 2,
                    suggestedCashout: suggestedCashout.value,
                    onCashout: cashout,
                    onCancel: hideForms
                  }, null, 8 /* PROPS */, ["suggestedCashout"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          (hasPlayers.value)
            ? (_openBlock(), _createBlock(_unref(Block), { key: 1 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(PlayerTable, {
                      players: playersInGame.value,
                      isCashgameRunning: isRunning.value,
                      onPlayerSelected: onSelectPlayer,
                      onDeleteAction: onDeleteAction,
                      onSaveAction: onSaveAction,
                      canEdit: canEdit.value,
                      bunchId: _unref(slug),
                      localization: _unref(localization)
                    }, null, 8 /* PROPS */, ["players", "isCashgameRunning", "canEdit", "bunchId", "localization"])
                  ])
                ]),
                _: 1 /* STABLE */
              }))
            : (_openBlock(), _createBlock(_unref(Block), { key: 2 }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" No one has joined the game yet. ")
                ])),
                _: 1 /* STABLE */
              }))
        ]),
        aside2: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(ValueList), null, {
                default: _withCtx(() => [
                  (showStartTime.value)
                    ? (_openBlock(), _createBlock(_unref(ValueListKey), { key: 0 }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("Start Time")
                        ])),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  (showStartTime.value)
                    ? (_openBlock(), _createBlock(_unref(ValueListValue), { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(formattedStartTime.value), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  (showEndTime.value)
                    ? (_openBlock(), _createBlock(_unref(ValueListKey), { key: 2 }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("End Time")
                        ])),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  (showEndTime.value)
                    ? (_openBlock(), _createBlock(_unref(ValueListValue), { key: 3 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(formattedEndTime.value), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  (showDuration.value)
                    ? (_openBlock(), _createBlock(_unref(ValueListKey), { key: 4 }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode("Duration")
                        ])),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  (showDuration.value)
                    ? (_openBlock(), _createBlock(_unref(ValueListValue), { key: 5 }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(DurationText), { value: durationMinutes.value }, null, 8 /* PROPS */, ["value"])
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  _createVNode(_unref(ValueListKey), null, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("Location")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(ValueListValue), null, {
                    default: _withCtx(() => [
                      (isEditing.value)
                        ? (_openBlock(), _createBlock(LocationDropdown, {
                            key: 0,
                            locations: _unref(locations),
                            modelValue: locationId.value,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((locationId).value = $event))
                          }, null, 8 /* PROPS */, ["locations", "modelValue"]))
                        : (_openBlock(), _createBlock(_unref(CustomLink), {
                            key: 1,
                            url: locationUrl.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(locationName.value), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["url"]))
                    ]),
                    _: 1 /* STABLE */
                  }),
                  (isPartOfEvent.value || isEditing.value)
                    ? (_openBlock(), _createBlock(_unref(ValueListKey), { key: 6 }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode("Event")
                        ])),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  (isPartOfEvent.value || isEditing.value)
                    ? (_openBlock(), _createBlock(_unref(ValueListValue), { key: 7 }, {
                        default: _withCtx(() => [
                          (isEditing.value)
                            ? (_openBlock(), _createBlock(EventDropdown, {
                                key: 0,
                                events: _unref(events),
                                modelValue: eventId.value,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((eventId).value = $event))
                              }, null, 8 /* PROPS */, ["events", "modelValue"]))
                            : (_openBlock(), _createBlock(_unref(CustomLink), {
                                key: 1,
                                url: eventUrl.value
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(eventName.value), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["url"]))
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  (isPlayerSelectionEnabled.value)
                    ? (_openBlock(), _createBlock(_unref(ValueListKey), { key: 8 }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("Player")
                        ])),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  (isPlayerSelectionEnabled.value)
                    ? (_openBlock(), _createBlock(_unref(ValueListValue), { key: 9 }, {
                        default: _withCtx(() => [
                          _createVNode(PlayerDropdown, {
                            players: allPlayers.value,
                            modelValue: selectedPlayerId.value,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedPlayerId).value = $event))
                          }, null, 8 /* PROPS */, ["players", "modelValue"])
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          (canEdit.value)
            ? (_openBlock(), _createBlock(_unref(Block), { key: 0 }, {
                default: _withCtx(() => [
                  (isEditing.value)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_unref(CustomButton), {
                          onClick: onSave,
                          type: "action",
                          text: "Save"
                        }),
                        _createVNode(_unref(CustomButton), {
                          onClick: onCancelEdit,
                          text: "Cancel"
                        })
                      ], 64 /* STABLE_FRAGMENT */))
                    : (_openBlock(), _createBlock(_unref(CustomButton), {
                        key: 1,
                        onClick: onEdit,
                        type: "action",
                        text: "Edit Cashgame"
                      }))
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          (isEditing.value && !hasPlayers.value)
            ? (_openBlock(), _createBlock(_unref(Block), { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_unref(CustomButton), {
                    onClick: onDelete,
                    type: "action",
                    text: "Delete"
                  })
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }),
      (hasPlayers.value)
        ? (_openBlock(), _createBlock(_unref(PageSection), { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_unref(Block), null, {
                default: _withCtx(() => [
                  _createVNode(GameChart, { players: playersInGame.value }, null, 8 /* PROPS */, ["players"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})