import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "validation-error"
}

import api from '@/api';
import { Layout } from '@/components/Layouts';
import { Block, CustomButton, PageHeading, PageSection } from '@/components/Common';
import { AxiosError } from 'axios';
import { ApiError } from '@/models/ApiError';
import { ApiParamsChangePassword } from '@/models/ApiParamsChangePassword';
import { computed, ref } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import { MessageResponse } from '@/response/MessageResponse';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangePasswordPage',
  setup(__props) {

const oldPassword = ref('');
const newPassword = ref('');
const repeat = ref('');
const errorMessage = ref('');
const passwordWasSaved = ref(false);

const hasError = computed(() => !!errorMessage.value);

const save = async () => {
  errorMessage.value = '';

  if (repeat.value !== newPassword.value) {
    errorMessage.value = "Passwords doesn't match";
    return;
  }

  resetPasswordMutation.mutate();
};

const resetPasswordMutation = useMutation({
  mutationFn: async (): Promise<MessageResponse> => {
    const params: ApiParamsChangePassword = {
      oldPassword: oldPassword.value,
      newPassword: newPassword.value,
    };

    const response = await api.changePassword(params);
    return response.data;
  },
  onSuccess: () => {
    passwordWasSaved.value = true;
  },
  onError: (error: AxiosError<ApiError>) => {
    const message = error.response?.data.message || 'Unknown Error';
    errorMessage.value = message;
  },
});

const back = () => {
  history.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: true
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "Change Password" })
            ]),
            _: 1 /* STABLE */
          }),
          (passwordWasSaved.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_unref(Block), null, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createElementVNode("p", null, "Your password was changed", -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }),
                _createVNode(_unref(Block), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(CustomButton), {
                      type: "action",
                      onClick: back,
                      text: "Back"
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ], 64 /* STABLE_FRAGMENT */))
            : (_openBlock(), _createBlock(_unref(Block), { key: 1 }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, [
                    _cache[4] || (_cache[4] = _createElementVNode("label", {
                      class: "label",
                      for: "oldPassword"
                    }, "Old Password", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      class: "textfield",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((oldPassword).value = $event)),
                      id: "oldPassword",
                      type: "password"
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, oldPassword.value]
                    ])
                  ]),
                  _createElementVNode("p", null, [
                    _cache[5] || (_cache[5] = _createElementVNode("label", {
                      class: "label",
                      for: "newPassword"
                    }, "New Password", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      class: "textfield",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((newPassword).value = $event)),
                      id: "newPassword",
                      type: "password"
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, newPassword.value]
                    ])
                  ]),
                  _createElementVNode("p", null, [
                    _cache[6] || (_cache[6] = _createElementVNode("label", {
                      class: "label",
                      for: "repeat"
                    }, "Repeat New Password", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      class: "textfield",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((repeat).value = $event)),
                      id: "repeat",
                      type: "password"
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, repeat.value]
                    ])
                  ]),
                  (hasError.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(errorMessage.value), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("p", null, [
                    _createVNode(_unref(CustomButton), {
                      onClick: save,
                      type: "action",
                      text: "Save"
                    }),
                    _createVNode(_unref(CustomButton), {
                      onClick: back,
                      text: "Cancel"
                    })
                  ])
                ]),
                _: 1 /* STABLE */
              }))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})