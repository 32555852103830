import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import format from '@/format';
import { Localization } from '@/models/Localization';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CurrencyText',
  props: {
    value: {},
    localization: {}
  },
  setup(__props: any) {

const props = __props;

const formattedValue = computed(() => format.currency(props.value, props.localization));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", null, _toDisplayString(formattedValue.value), 1 /* TEXT */))
}
}

})