import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Layout } from '@/components/Layouts';
import BunchNavigation from '@/components/Navigation/BunchNavigation.vue';
import { DefinitionList, DefinitionData, DefinitionTerm } from '@/components/Common/DefinitionList';
import { Block, PageHeading, PageSection, DurationText } from '@/components/Common';
import MatrixTable from '@/components/Matrix/MatrixTable.vue';
import { computed } from 'vue';
import { useParams, useBunch, useEventList, useEventGameList } from '@/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventDetailsPage',
  setup(__props) {

const { slug, eventId } = useParams();
const { getEvent, eventsReady } = useEventList(slug.value);
const { localization, bunchReady } = useBunch(slug.value);
const { eventGames, eventGamesReady } = useEventGameList(slug.value, eventId.value);

const name = computed(() => {
  if (event.value) return event.value.name;
  return '';
});

const event = computed(() => getEvent(eventId.value));
const duration = computed(() => eventGames.value.reduce((totalTime, game) => totalTime + game.duration, 0));
const location = computed(() => (eventGames.value.length > 0 ? eventGames.value[0].location.name : 'n/a'));
const ready = computed(() => bunchReady.value && eventsReady.value && eventGamesReady.value);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    "top-nav": _withCtx(() => [
      _createVNode(BunchNavigation)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: name.value }, null, 8 /* PROPS */, ["text"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(DefinitionList), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(DefinitionTerm), null, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Location")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(DefinitionData), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(location.value), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(DefinitionTerm), null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Total Time Played")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_unref(DefinitionData), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DurationText), { value: duration.value }, null, 8 /* PROPS */, ["value"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(MatrixTable, {
                slug: _unref(slug),
                games: _unref(eventGames),
                localization: _unref(localization)
              }, null, 8 /* PROPS */, ["slug", "games", "localization"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})