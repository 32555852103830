import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import api from '@/api';
import { Layout } from '@/components/Layouts';
import { Block, CustomButton, ErrorMessage, PageHeading, PageSection } from '@/components/Common';
import { User } from '@/models/User';
import { ValueList, ValueListKey, ValueListValue } from '@/components/Common/ValueList';
import { computed, onMounted, ref, watch } from 'vue';
import { useParams, useUser, useCurrentUser } from '@/composables';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { userKey, userListKey } from '@/queries/queryKeys';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserDetailsPage',
  setup(__props) {

const { userName } = useParams();
const { user, userReady } = useUser(userName.value);
const { currentUser, isAdmin, currentUserReady } = useCurrentUser();
const queryClient = useQueryClient();

const displayName = ref('');
const realName = ref('');
const email = ref('');
const isEditing = ref(false);
const errorMessage = ref('');

const canEdit = computed(() => isAdmin.value || isCurrentUser.value);
const isCurrentUser = computed(() => currentUser.value?.userName == user.value?.userName);
const canChangePassword = computed(() => isCurrentUser.value);
const ready = computed(() => currentUserReady.value && userReady.value);
const avatarUrl = computed(() => user.value?.avatar);
const hasError = computed(() => !!errorMessage.value);

const saveMutation = useMutation({
  mutationFn: async () => {
    errorMessage.value = '';

    var userToSave = {
      ...user.value,
      displayName: displayName.value,
      realName: realName.value,
      email: email.value,
    };

    await api.updateUser(userToSave);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: userListKey() });
    queryClient.invalidateQueries({ queryKey: userKey(user.value.userName) });
    isEditing.value = false;
  },
  onError: () => {
    errorMessage.value = 'Server error';
  },
});

const cancel = () => {
  if (user.value) setMembers(user.value);
  isEditing.value = false;
};

const setMembers = (user: User) => {
  displayName.value = user.displayName;
  realName.value = user.realName || '';
  email.value = user.email || '';
};

const edit = () => {
  isEditing.value = true;
};

watch(user, (u) => {
  setMembers(u);
});

onMounted(() => {
  if (user.value) setMembers(user.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        aside1: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: avatarUrl.value,
                alt: "User avatar"
              }, null, 8 /* PROPS */, _hoisted_1)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: _unref(userName) }, null, 8 /* PROPS */, ["text"])
            ]),
            _: 1 /* STABLE */
          }),
          (isEditing.value)
            ? (_openBlock(), _createBlock(_unref(Block), { key: 0 }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, [
                    _cache[3] || (_cache[3] = _createElementVNode("label", {
                      class: "label",
                      for: "display-name"
                    }, "Display Name", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      class: "textfield",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((displayName).value = $event)),
                      id: "display-name",
                      type: "text"
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, displayName.value]
                    ])
                  ]),
                  _createElementVNode("p", null, [
                    _cache[4] || (_cache[4] = _createElementVNode("label", {
                      class: "label",
                      for: "real-name"
                    }, "Real Name", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      class: "textfield",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((realName).value = $event)),
                      id: "real-name",
                      type: "text"
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, realName.value]
                    ])
                  ]),
                  _createElementVNode("p", null, [
                    _cache[5] || (_cache[5] = _createElementVNode("label", {
                      class: "label",
                      for: "email"
                    }, "Email", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      class: "textfield",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((email).value = $event)),
                      id: "email",
                      type: "text"
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, email.value]
                    ])
                  ]),
                  _createVNode(_unref(ErrorMessage), { message: errorMessage.value }, null, 8 /* PROPS */, ["message"]),
                  _createElementVNode("p", null, [
                    _createVNode(_unref(CustomButton), {
                      onClick: _unref(saveMutation).mutate,
                      type: "action",
                      text: "Save"
                    }, null, 8 /* PROPS */, ["onClick"]),
                    _createVNode(_unref(CustomButton), {
                      onClick: cancel,
                      text: "Cancel"
                    })
                  ])
                ]),
                _: 1 /* STABLE */
              }))
            : (_openBlock(), _createBlock(_unref(Block), { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_unref(ValueList), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(ValueListKey), null, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode("Display Name")
                        ])),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_unref(ValueListValue), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(displayName.value), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      (canEdit.value)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createVNode(_unref(ValueListKey), null, {
                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createTextVNode("Real Name")
                              ])),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_unref(ValueListValue), null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(realName.value), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_unref(ValueListKey), null, {
                              default: _withCtx(() => _cache[8] || (_cache[8] = [
                                _createTextVNode("Email")
                              ])),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_unref(ValueListValue), null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(email.value), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ], 64 /* STABLE_FRAGMENT */))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })),
          (canEdit.value && !isEditing.value)
            ? (_openBlock(), _createBlock(_unref(Block), { key: 2 }, {
                default: _withCtx(() => [
                  _createVNode(_unref(CustomButton), {
                    type: "action",
                    onClick: edit,
                    text: "Edit"
                  }),
                  (canChangePassword.value)
                    ? (_openBlock(), _createBlock(_unref(CustomButton), {
                        key: 0,
                        type: "action",
                        url: "/user/changepassword",
                        text: "Change Password"
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})