import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

import { Layout } from '@/components/Layouts';
import { Block, PageHeading, PageSection } from '@/components/Common';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ApiDocsPage',
  setup(__props) {

const swaggerUrl = computed(() => `/api/swagger`);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": false,
    ready: true
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "Api" })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("p", null, " You can build your own applications that interact with Poker Bunch, by using the Poker Bunch API. You'll find everything you need to know right here. ", -1 /* HOISTED */)
            ])),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "module-heading" }, "Documentation", -1 /* HOISTED */)),
              _createElementVNode("p", null, [
                _cache[1] || (_cache[1] = _createTextVNode("For detailed documentation, have a look at the ")),
                _createElementVNode("a", {
                  href: swaggerUrl.value,
                  target: "_blank"
                }, "Swagger pages", 8 /* PROPS */, _hoisted_1)
              ])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("h2", { class: "module-heading" }, "Authentication", -1 /* HOISTED */),
              _createElementVNode("p", null, "To authenticate, your application needs to post a request to", -1 /* HOISTED */),
              _createElementVNode("pre", null, [
                _createElementVNode("code", null, "POST login")
              ], -1 /* HOISTED */),
              _createElementVNode("p", null, "Post the username and password as json:", -1 /* HOISTED */),
              _createElementVNode("pre", null, [
                _createElementVNode("code", null, "{\n  \"username\": \"[username]\",\n  \"password\": \"[password]\"\n}")
              ], -1 /* HOISTED */),
              _createElementVNode("p", null, "If your credentials are valid, the response will include a token, for example", -1 /* HOISTED */),
              _createElementVNode("pre", null, [
                _createElementVNode("code", null, "ABCDE")
              ], -1 /* HOISTED */),
              _createElementVNode("p", null, "For subsequent requests, include an Authorization header with the content", -1 /* HOISTED */),
              _createElementVNode("pre", null, [
                _createElementVNode("code", null, "bearer ABCDE")
              ], -1 /* HOISTED */)
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}
}

})