import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "player-row" }
const _hoisted_2 = { class: "player-row__name-and-time" }
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 0,
  class: "time"
}
const _hoisted_5 = { class: "player-row__amounts" }
const _hoisted_6 = { class: "player-row__small-chart" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "player-row__chart" }
const _hoisted_9 = { class: "player-row__actions" }

import PlayerAction from './PlayerAction.vue';
import urls from '@/urls';
import CashgameActionChart from '@/components/CashgameActionChart.vue';
import CashgameActionChartSmall from '@/components/CashgameActionChartSmall.vue';
import { CurrencyText, CustomLink, WinningsText } from '@/components/Common';
import { DetailedCashgamePlayer } from '@/models/DetailedCashgamePlayer';
import { computed, ref } from 'vue';
import { BuyinIcon, CashedOutIcon, InlineIcon, ReportIcon, TimeIcon } from '../Icons';
import { Localization } from '@/models/Localization';
import { SaveActionEmitData } from '@/models/SaveActionEmitData';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayerRow',
  props: {
    bunchId: {},
    player: {},
    isCashgameRunning: { type: Boolean },
    canEdit: { type: Boolean },
    localization: {}
  },
  emits: ["selected", "saveAction", "deleteAction"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isExpanded = ref(false);
const hasCashedOut = computed(() => props.player.hasCashedOut());
const showCheckmark = computed(() => props.isCashgameRunning && hasCashedOut.value);
const isReportTimeEnabled = computed(() => props.isCashgameRunning);
const lastReportTime = computed(() => props.player.getLastReportTime());
const calculatedBuyin = computed(() => props.player.getBuyin());
const stack = computed(() => props.player.getStack());
const winnings = computed(() => props.player.getWinnings());
const url = computed(() => urls.player.details(props.bunchId, props.player.id));
const showDetails = computed(() => isExpanded.value);
const expand = () => (isExpanded.value = true);
const collapse = () => (isExpanded.value = false);
const toggle = () => (isExpanded.value = !isExpanded.value);
const onSelected = () => emit('selected', props.player.id);
const onDeleteAction = (id: string) => emit('deleteAction', id);
const onSaveAction = (data: SaveActionEmitData) => emit('saveAction', data);
const click = () => toggle();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "player-row__row-wrapper",
      onClick: toggle
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", {
            class: "player-color-box",
            style: _normalizeStyle({ backgroundColor: _ctx.player.color }),
            onClick: onSelected
          }, null, 4 /* STYLE */),
          _createElementVNode("a", {
            class: "player-row__name",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
            href: url.value
          }, _toDisplayString(_ctx.player.name), 9 /* TEXT, PROPS */, _hoisted_3),
          _createVNode(_unref(InlineIcon), null, {
            default: _withCtx(() => [
              (showCheckmark.value)
                ? (_openBlock(), _createBlock(_unref(CashedOutIcon), {
                    key: 0,
                    title: "Cashed out"
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        (isReportTimeEnabled.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_unref(InlineIcon), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(TimeIcon), { title: "Last report" })
                ]),
                _: 1 /* STABLE */
              }),
              _cache[1] || (_cache[1] = _createTextVNode()),
              _createElementVNode("span", null, _toDisplayString(lastReportTime.value), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _createVNode(_unref(InlineIcon), null, {
            default: _withCtx(() => [
              _createVNode(_unref(BuyinIcon), { title: "Buy in" })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(CurrencyText), {
            value: calculatedBuyin.value,
            localization: _ctx.localization
          }, null, 8 /* PROPS */, ["value", "localization"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_unref(InlineIcon), null, {
            default: _withCtx(() => [
              _createVNode(_unref(ReportIcon), { title: "Total Stacks" })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(CurrencyText), {
            value: stack.value,
            localization: _ctx.localization
          }, null, 8 /* PROPS */, ["value", "localization"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_unref(WinningsText), {
            value: winnings.value,
            localization: _ctx.localization
          }, null, 8 /* PROPS */, ["value", "localization"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(CashgameActionChartSmall, { player: _ctx.player }, null, 8 /* PROPS */, ["player"])
      ])
    ]),
    (showDetails.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(CashgameActionChart, { player: _ctx.player }, null, 8 /* PROPS */, ["player"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.player.actions, (action) => {
              return (_openBlock(), _createBlock(PlayerAction, {
                action: action,
                key: action.id,
                localization: _ctx.localization,
                onDeleteAction: onDeleteAction,
                onSaveAction: onSaveAction,
                canEdit: _ctx.canEdit
              }, null, 8 /* PROPS */, ["action", "localization", "canEdit"]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})