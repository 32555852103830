import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Layout } from '@/components/Layouts';
import BunchNavigation from '@/components/Navigation/BunchNavigation.vue';
import EventList from '@/components/EventList/EventList.vue';
import { Block, CustomButton, PageHeading, PageSection } from '@/components/Common';
import urls from '@/urls';
import { computed } from 'vue';
import { useParams, useEventList } from '@/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventListPage',
  setup(__props) {

const { slug } = useParams();
const { events, eventsReady } = useEventList(slug.value);

const addEventUrl = computed(() => urls.event.add(slug.value));
const ready = computed(() => eventsReady.value);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    "top-nav": _withCtx(() => [
      _createVNode(BunchNavigation)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        aside1: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(CustomButton), {
                url: addEventUrl.value,
                type: "action",
                text: "Add event"
              }, null, 8 /* PROPS */, ["url"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "Events" })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(EventList, { events: _unref(events) }, null, 8 /* PROPS */, ["events"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})