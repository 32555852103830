import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Layout } from '@/components/Layouts';
import BunchNavigation from '@/components/Navigation/BunchNavigation.vue';
import { Block, PageHeading, PageSection } from '@/components/Common';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useParams, useLocationList } from '@/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'LocationDetailsPage',
  setup(__props) {

const { slug } = useParams();
const route = useRoute();
const { getLocation, locationsReady } = useLocationList(slug.value);

const name = computed(() => {
  if (location.value) return location.value.name;
  return '';
});

const location = computed(() => getLocation(locationId.value));
const locationId = computed(() => route.params.id as string);
const ready = computed(() => locationsReady.value);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    "top-nav": _withCtx(() => [
      _createVNode(BunchNavigation)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: name.value }, null, 8 /* PROPS */, ["text"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})