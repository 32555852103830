import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

import { Layout } from '@/components/Layouts';
import BunchNavigation from '@/components/Navigation/BunchNavigation.vue';
import CashgameNavigation from '@/components/Navigation/CashgameNavigation.vue';
import OverviewTable from '@/components/Overview/OverviewTable.vue';
import OverviewStatus from '@/components/Overview/OverviewStatus.vue';
import YearMatrixTable from '@/components/YearMatrix/YearMatrixTable.vue';
import { Block, PageHeading, PageSection } from '@/components/Common';
import { computed } from 'vue';
import { useParams, useBunch, useGameList, useCurrentGameList } from '@/composables';
import { ArchiveCashgame } from '@/models/ArchiveCashgame';
import archiveHelper from '@/ArchiveHelper';
import gameSorter from '@/GameSorter';
import { CashgameSortOrder } from '@/models/CashgameSortOrder';


export default /*@__PURE__*/_defineComponent({
  __name: 'OverviewPage',
  setup(__props) {

const { slug } = useParams();
const { bunch, localization, bunchReady } = useBunch(slug.value);
const { allGames, getSelectedGames, hasGames, gamesReady } = useGameList(slug.value);
const { currentGames, currentGamesReady } = useCurrentGameList(slug.value);

const ready = computed(() => {
  return bunchReady.value && gamesReady.value && currentGamesReady.value;
});

const currentYearGames = computed((): ArchiveCashgame[] => {
  const selectedGames = getSelectedGames(currentYear.value);
  return gameSorter.sort(selectedGames, CashgameSortOrder.Date);
});

const currentYear = computed(() => archiveHelper.getCurrentYear(allGames.value));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Layout), {
    "require-user": true,
    ready: ready.value
  }, {
    "top-nav": _withCtx(() => [
      _createVNode(BunchNavigation)
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(PageSection), null, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(CashgameNavigation, { page: "index" })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(PageSection), null, {
        aside1: _withCtx(() => [
          _createVNode(OverviewStatus, { games: _unref(currentGames) }, null, 8 /* PROPS */, ["games"])
        ]),
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              _createVNode(_unref(PageHeading), { text: "Current Rankings" }),
              (_unref(hasGames))
                ? (_openBlock(), _createBlock(OverviewTable, {
                    key: 0,
                    bunch: _unref(bunch),
                    games: currentYearGames.value,
                    localization: _unref(localization)
                  }, null, 8 /* PROPS */, ["bunch", "games", "localization"]))
                : (_openBlock(), _createElementBlock("p", _hoisted_1, "The rankings will be displayed here when you have played your first game."))
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_unref(PageSection), { "is-wide": false }, {
        default: _withCtx(() => [
          _createVNode(_unref(Block), null, {
            default: _withCtx(() => [
              (_unref(hasGames))
                ? (_openBlock(), _createBlock(_unref(PageHeading), {
                    key: 0,
                    text: "Yearly Rankings"
                  }))
                : _createCommentVNode("v-if", true),
              (_unref(hasGames))
                ? (_openBlock(), _createBlock(YearMatrixTable, {
                    key: 1,
                    bunch: _unref(bunch),
                    games: _unref(allGames),
                    localization: _unref(localization)
                  }, null, 8 /* PROPS */, ["bunch", "games", "localization"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["ready"]))
}
}

})